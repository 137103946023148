import React from 'react';
import styled from 'styled-components';
import { P } from './Typography';
import GreenTickIcon from '../assets/icons/tick.svg';

const SubsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 0;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: normal;
    gap: 25px;
  }
`;

const CardWrapper = styled.div<{ isRecommended?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  padding: 26px 20px 13px;

  &:not(:last-child) {
    border-right: 1px solid #eeeeee;
  }

  ${({ isRecommended }) =>
    isRecommended &&
    `border: 2px solid #027AFF !important; border-radius: 10px; position: relative; margin: -35px 0;`}

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 900px) {
    border-radius: 10px;
    border: 1px solid #eeeeee;
    padding-bottom: 30px;
    ${({ isRecommended }) => isRecommended && `margin: 0;`}
  }
`;

const CardTitle = styled(P)`
  color: #00bc82;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: normal;
`;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0;
`;

const Price = styled(P)`
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 22px;
`;

const Cents = styled(P)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 14px;
  margin-top: -10px;
`;

const Vat = styled(P)`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
  margin-left: 5px;
`;

const Month = styled(P)`
  color: #495b6c;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: normal;
`;

const Divider = styled.div`
  display: flex;
  height: 1px;
  background-color: #dbe3eb;
  margin: 23px 0;
`;

const SectionTitle = styled(P)`
  text-align: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 16px;
`;

const PointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Point = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;
const PointText = styled(P)`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
`;

const Span = styled.span`
  font-weight: 700;
`;

const GreenTick = styled(GreenTickIcon)`
  color: #2cd19e;
  padding-top: 5px;
`;
const TickWrapper = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HasFromSpan = styled.span`
  font-size: 1rem;
`;

const RecommendMark = styled(P)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: calc(100% - 35px * 2);
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 0px 0px 10px 10px;
  background: #027aff;
`;

const TickIcon = () => (
  <TickWrapper>
    <GreenTick />
  </TickWrapper>
);

interface SolutionComponentProps {
  cards: {
    isRecommended?: boolean;
    hasFrom?: boolean;
    title: string;
    price: string;
    products: string;
    integrations: number;
    users: number;
    orderCondition: string;
    orders: number;
    payments: string;
  }[];
  isB2bPage?: boolean;
}
const SubscriptionComponent: React.FunctionComponent<
  SolutionComponentProps
> = ({ cards, isB2bPage }) => (
  <SubsWrapper>
    {cards.map((el) => (
      <CardWrapper isRecommended={el.isRecommended}>
        {el.isRecommended && <RecommendMark>RECOMMENDED plan</RecommendMark>}
        <CardTitle style={el.isRecommended ? { marginTop: 33 } : undefined}>
          {el.title}
        </CardTitle>
        <PriceRow>
          <Price>
            {el.hasFrom && <HasFromSpan>from</HasFromSpan>} {el.price}
          </Price>
          <Cents>99</Cents>
          <Vat>+VAT</Vat>
        </PriceRow>
        <Month>/ month</Month>
        <Divider />
        <SectionTitle>Product and Inventory Management</SectionTitle>
        <PointsWrapper style={{ marginBottom: 20 }}>
          <Point>
            <TickIcon />
            <PointText>
              Access to all the features under Product and Inventory
            </PointText>
          </Point>
          <Point>
            <TickIcon />
            <PointText>
              Up to <Span>{el.products}</Span> products hosted on the platform
            </PointText>
          </Point>
          <Point>
            <TickIcon />
            <PointText>
              Comes with free invoice generation{` `}
              {isB2bPage ? `` : `with payment links`}
            </PointText>
          </Point>
          <Point>
            <TickIcon />
            <PointText>
              Integrated with accounting solutions, ERPs and more.
            </PointText>
          </Point>
        </PointsWrapper>

        <SectionTitle>Order Management</SectionTitle>

        <PointsWrapper>
          <Point>
            <TickIcon />
            <PointText>
              Ability to connect <Span>{el.integrations}</Span>
              {` `}
              {el.integrations === 1 ? `integration` : `integrations`}
            </PointText>
          </Point>
          <Point>
            <TickIcon />
            <PointText>
              <Span>{el.users}</Span> user seats
            </PointText>
          </Point>
          <Point>
            <TickIcon />
            <PointText>
              {el.orderCondition} <Span>{el.orders} B2B orders</Span> per month
            </PointText>
          </Point>
          {!isB2bPage && (
            <>
              <Point>
                <TickIcon />
                <PointText>
                  Free payment (single domestic payments) up to{` `}
                  <Span>{el.payments}</Span> per month
                </PointText>
              </Point>
              <Point>
                <TickIcon />
                <PointText>
                  Other payments as per the payment subscription
                </PointText>
              </Point>
            </>
          )}
        </PointsWrapper>
      </CardWrapper>
    ))}
  </SubsWrapper>
);

export default SubscriptionComponent;
